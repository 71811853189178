
import React, { useContext, useEffect, useRef, useState } from 'react';
import GlobalStyles from './components/GlobalStyles/GlobalStyles';
import AutomationFlow from './core/AutomationFlow/AutomationFlow';
import NavbarNested from './core/Navbar/Navbar';
import { useSearchParams } from "react-router-dom";
import setupInterceptors from './request/setupInterceptors';
import { NotificationsProvider } from '@mantine/notifications';
import {ScriptProvider} from "./core/context/ScriptContext";
import {DataFlowProvider} from "./core/context/DataFlowContext";
import { ColorSchemeProvider, MantineProvider } from '@mantine/core';
import { ThemeProvider } from 'styled-components';
import { darkTheme, lightTheme } from './components/Theme/theme';
import { AppSettingsContext } from './core/context/AppSettingsContext';
import { ReactFlowProvider } from 'reactflow';
import { useTranslation } from 'react-i18next';
import './locales/index';

function App() {
    //get params from app when embed iframe
    const [searchParams] = useSearchParams();
    const isClone = searchParams.get('is_clone_script') === "true"
    const settingsContext = useContext(AppSettingsContext)
    const autoVersion = Number(searchParams.get('autoVersion'))
    const { i18n } = useTranslation();

    if (searchParams.get('token')) {
        localStorage.setItem('token', searchParams.get('token'));
    }

    if (searchParams.get('v')) {
        localStorage.setItem('appVersion', searchParams.get('v'));
    }

    if (searchParams.get('base_url')) {
        localStorage.setItem('base_url', searchParams.get('base_url'));
    }

    if (searchParams.get('base_url_v4')) {
        localStorage.setItem('base_url_v4', searchParams.get('base_url_v4'));
    }

    //setup add token... to axios request
    setupInterceptors();

    //ref can use handleSave func in navbar
    const automationFlowRef = useRef();
    const handleSaveAutomationFlow = (script, saveAs) => automationFlowRef.current.handleSave(script, saveAs);
    const handleRunAutomationFlow = (uuidBrowser, browserName) => {
        automationFlowRef.current.handleRunTest(uuidBrowser, browserName)
    };

    const handleShowLogAutomationFlow = () => {
        automationFlowRef.current.handleShowLog()
    }

    const [colorScheme, setColorScheme] = useState('light');
    const theme = colorScheme === 'light' ? lightTheme : darkTheme;
    
    useEffect(() => {
        const changeLanguage = (e) => {
            if (e.data.api !== "hidemium") return;
            if (e.data.type === "changeLanguage") {
                settingsContext.setSettings((settings) => ({...settings, language: e.data.content }))
                return;
            }
        }
        window.addEventListener("message", changeLanguage);
        return () => window.removeEventListener("message", changeLanguage)
    }, [])

    useEffect(() => {
        settingsContext.setSettings((settings) => ({...settings, autoVersion: autoVersion || 0 }))
    }, [])

    useEffect(() => {
        window.addEventListener("message", function (e) {
            switch (e.data.type) {
                case "changeLanguage":
                case "remove_script_draft":
                case "changeStore":
                    break;
                case "user-agent-config":
                    settingsContext.userAgentConfig.current = e.data.data
                    break;
                case "user-charge":
                    settingsContext.userCharge.current = e.data.data
                    break;
                case "dark-mode":
                    setColorScheme(e.data.data)
                    break;
                case "language":
                    i18n.changeLanguage(e.data.data);
                    break;
                default:
                    // setReceivedMessage(e.data.darkMode);
                    break;
            }
        });
    }, []);
    
    useEffect(() => {
        document.addEventListener('contextmenu', event => event.preventDefault());
    }, [])

    useEffect(() => {
        const onMessage = (e) => {
            if(e.data.api != 'hidemium') return;
            if (e.data.type === "changeStore") {
                const isCloud = e.data.content !== 'local'
                const updateId = searchParams.get('updateId')
                settingsContext.setSettings((settings) => ({...settings, isCloud: isCloud, updateId: updateId }))
                return;
            }
        }

        window.addEventListener("message", onMessage);
        return () => window.removeEventListener("message", onMessage)
    }, [])

    const handleDbClick = (newNode) => {
        automationFlowRef.current.handleDbClick(newNode);
    }

    const handleRemoveNodes = () => {
        automationFlowRef.current.handleRemoveNodes()
    }

    const handleShowVariables = () => {
        automationFlowRef.current.handleShowVariables()
    }

    const handleCustomNode = (customNodes) => {
        automationFlowRef.current.handleCustomNode(customNodes)
    }

    return (
        <ColorSchemeProvider colorScheme={colorScheme}>
            <MantineProvider theme={{ colorScheme }} withGlobalStyles withNormalizeCSS>
                    <GlobalStyles>
                        <NotificationsProvider position="top-right" style={{zIndex: 1001}} >
                            <div className="main">
                                <ScriptProvider>
                                <DataFlowProvider>
                                        <NavbarNested updateId={(searchParams.get('updateId') && isClone)??null} handleSaveAutomationFlow={handleSaveAutomationFlow}  handleRunAutomationFlow={handleRunAutomationFlow} 
                                        handleShowLogAutomationFlow={handleShowLogAutomationFlow} handleDbClick={handleDbClick}
                                        handleShowVariables={handleShowVariables}
                                        handleCustomNode={handleCustomNode}
                                        />
                                        <ThemeProvider theme={theme}>
                                            <ReactFlowProvider>
                                                <AutomationFlow updateId={searchParams.get('updateId')} ref={automationFlowRef} handleSaveAutomationFlow={handleSaveAutomationFlow}  
                                                handleRunAutomationFlow={handleRunAutomationFlow} 
                                                handleShowLogAutomationFlow={handleShowLogAutomationFlow}
                                                handleRemoveNodes={handleRemoveNodes}
                                                />
                                            </ReactFlowProvider>
                                        </ThemeProvider>
                                </DataFlowProvider>
                                </ScriptProvider>
                            </div>
                        </NotificationsProvider>
                    </GlobalStyles>
            </MantineProvider>
        </ColorSchemeProvider>
    );
}

export default App;
