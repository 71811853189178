import { Button, Input } from '@mantine/core';
import { IconFileUpload } from '@tabler/icons';
import React, { useEffect, useRef, useState } from 'react'

const CustomNode = ({handleSetDataBaseModal, nodeData}) => {
  const [variables, setVariables] = useState([])
  const fileRef = useRef(null)
  const fileIdxRef = useRef(null)

  useEffect(() => {
    if (Array.isArray(nodeData?.data?.options?.variables)) {
      setVariables(nodeData?.data?.options?.variables)
    }
  }, [])

  const onChangeVariable = ({ index, value }) => {
    setVariables((vari) => {
      vari[index].data = value
      return [...vari]
    })
  }

  useEffect(() => {
    handleSetDataBaseModal({...nodeData?.data?.options, variables})
  }, [variables])

  const onSelectFile = (event) => {
    onChangeVariable({ value: event.target.files[0].path, index: fileIdxRef.current })
  }

  return (
    <>
      <ul>
        {
          variables.map((item, index) => (
            <li
              key={index}
              style={{
                display: 'flex',
                marginBottom: 12
              }}
            >
              <Input value={item.label} disabled/>
              <span style={{ margin: '0 12px' }}>=</span>
              <Input
                value={item.data}
                style={{ flex: 1 }}
                onChange={e => onChangeVariable({ value: e.target.value, index })}
              />
              <Button
                style={{
                    display: "flex",
                    marginLeft: "18px",
                    paddingRight: "12px",
                    paddingLeft: "12px"
                }}
                variant="default"
                  onClick={() => { fileIdxRef.current = index; fileRef.current.click() }}
              >
                <IconFileUpload size={16} />
              </Button>
            </li>
          ))
        }
      </ul>
      <input
        style={{ display: 'none' }}
        type="file"
        multiple={false}
        ref={fileRef}
        onChange={onSelectFile}
      />
    </>
  )
}

export default CustomNode
