import {
  IconCategory,
  IconCodeMinus,
  IconKeyboard,
  IconMouse,
} from "@tabler/icons";
import { v4 as uuidv4 } from "uuid";

import { DEFAULT_SWITCH_EXTENSION_POPUP_DATA } from "../../components/Modal/BodyModal/Navigation/SwitchExtensionPopup";
import { DEFAULT_SWITCH_FRAME_DATA } from "../../components/Modal/BodyModal/Navigation/SwitchFrame";
import "./navbar.css";
import { versions } from "./automationVersion";

export const mockdata = [
  {
    label: "Navigator",
    icon: IconCategory,
    initiallyOpened: true,
    links: [
      {
        data: {
          version: versions[0].autoVersion,
          type: "newTab",
          typeNode: "normalNode",
          label: "New tab",
          icon: "IconExternalLink",
          options: { newTab: "" },
          search: "New tab",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "activeTab",
          typeNode: "normalNode",
          label: "Active tab",
          icon: "IconLayersLinked",
          options: {
            title: "url",
            comparison: "equal",
            tabInfo: "",
          },
          search: "Active tab",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "closeTab",
          typeNode: "normalNode",
          label: "Close tab",
          icon: "IconSquareX",
          options: { closeTabType: "current", tabNumber: 1 },
          search: "Close tab",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "openUrl",
          typeNode: "normalNode",
          label: "Open url",
          icon: "IconLink",
          options: {
            url: "",
            timeout: 30000,
            waitUntil: "domcontentloaded",
            referer: "",
          },
          search: "Open url",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "reloadPage",
          typeNode: "normalNode",
          label: "Reload page",
          icon: "IconRefresh",
          options: {
            reload: true,
            timeout: 30000,
          },
          search: "Reload page",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "screenShot",
          typeNode: "normalNode",
          label: "Screenshot",
          icon: "IconScreenshot",
          options: {
            screen_shot_name: Number(new Date()).toString(),
            isFullScreen: false,
            type_format: "jpeg",
            modeSave: "default",
            path_folder: "",
          },
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "switchFrame",
          typeNode: "normalNode",
          label: "Switch Frame",
          icon: "IconFrame",
          options: DEFAULT_SWITCH_FRAME_DATA,
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "switchExtensionPopup",
          typeNode: "normalNode",
          label: "Switch Extension popup",
          icon: "IconPuzzle",
          options: DEFAULT_SWITCH_EXTENSION_POPUP_DATA,
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "goBack",
          typeNode: "normalNode",
          label: "Go back",
          icon: "IconArrowBadgeLeft",
          options: { timeout: 0 },
          search: "Go back",
        },
      },
    ],
  },
  {
    label: "Mouse",
    icon: IconMouse,
    initiallyOpened: true,
    links: [
      {
        data: {
          version: versions[0].autoVersion,
          type: "click",
          typeNode: "normalNode",
          label: "Click",
          icon: "IconHandClick",
          options: {
            button: "left",
            buttonClick: "click",
            selectorType: "selector",
            elementSelect: "",
            elementOrder: "fixed",
            fixedElement: 1,
            randomElementFrom: 1,
            randomElementTo: 50,
            x: 0,
            y: 0,
          },
          search: "Click",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "mouseMovement",
          typeNode: "normalNode",
          label: "Mouse movement",
          icon: "IconDragDrop",
          options: {
            selectorType: "selector",
            elementSelect: "",
            elementOrder: "fixed",
            fixedElement: 1,
            randomElementFrom: 1,
            randomElementTo: 50,
            x: 0,
            y: 0,
            focus: false,
          },
          search: "Mouse movement",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "scroll",
          typeNode: "normalNode",
          label: "Scroll",
          icon: "IconGripVertical",
          options: {
            selectorType: "selector",
            elementSelect: "",
            fixedElement: 1,
            deltaX: 0,
            deltaY: 0,
          },
          search: "Scroll",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "drop-down-selector",
          typeNode: "normalNode",
          label: "Drop-down selector",
          icon: "IconSelect",
          options: null,
          search: "Drop-down selector",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "mouseWheel",
          typeNode: "normalNode",
          label: "Mouse wheel",
          icon: "IconMouse",
          options: {
            deltaX: 0,
            deltaY: 0,
          },
          search: "wheel",
        },
      },
    ],
  },
  {
    label: "Keyboard",
    icon: IconKeyboard,
    initiallyOpened: true,
    links: [
      {
        data: {
          version: versions[0].autoVersion,
          type: "pressKey",
          typeNode: "normalNode",
          label: "Press key",
          icon: "IconKeyboardShow",
          options: { pressKey: "" },
          search: "Press key",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "typeText",
          typeNode: "normalNode",
          label: "Type text",
          icon: "IconBallpen",
          options: null,
          search: "Type text",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "sendTextToSelector",
          typeNode: "normalNode",
          label: "Send text to selector",
          icon: "IconAlphabetLatin",
          options: {
            elementSelect: "",
            fixedElement: 1,
            content: "",
            contentType: "sequence",
            intervalTime: 100,
            timeout: 30000,
          },
          search: "Send text to selector",
        },
      },
    ],
  },
  {
    label: "Data",
    icon: IconKeyboard,
    initiallyOpened: true,
    links: [
      {
        data: {
          version: versions[0].autoVersion,
          type: "elementExists",
          typeNode: "normalNode",
          label: "Element exists",
          icon: "IconMinimize",
          options: {
            elementSelect: "",
            fixedElement: 1,
            isVisible: "true",
            timeout: 30000,
          },
          search: "Element exists",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "elementExistsV2",
          typeNode: "nodeElementExistsV2",
          label: "Element exists V2",
          icon: "IconMinimize",
          options: {
            elementSelects: [{
              id: uuidv4(),
              valueElementSelect: ""
            }],
            fixedElement: 1,
            isVisible: "true",
            timeout: 30000,
          },
          search: "Element exists v2",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "getUrl",
          typeNode: "normalNode",
          label: "Get URL",
          icon: "IconLine",
          options: {
            extractionType: "fullUrl",
            searchKeyValue: "",
            dataUrl: "",
          },
          search: "Get URL",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "getText",
          typeNode: "normalNode",
          label: "Get text",
          icon: "IconFileDots",
          options: {
            elementSelect: "",
            elementOrder: "fixed",
            fixedElement: 1,
            randomElementFrom: 1,
            randomElementTo: 50,
            outputVariable: "",
          },
          search: "Get text",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "getValue",
          typeNode: "normalNode",
          label: "Get value",
          icon: "IconAlignBoxLeftMiddle",
          options: {
            attributeName: "",
            outputVariable: "",
            elementSelect: "",
            elementOrder: "fixed",
            fixedElement: 1,
            randomElementFrom: 1,
            randomElementTo: 50,
          },
          search: "Get value",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "getAttributeValue",
          typeNode: "normalNode",
          label: "Get attribute value",
          icon: "Icon3dCubeSphere",
          options: {
            attributeName: "",
            outputVariable: "",
            elementSelect: "",
            elementOrder: "fixed",
            fixedElement: 1,
            randomElementFrom: 1,
            randomElementTo: 50,
          },
          search: "Get attribute value",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "random",
          typeNode: "normalNode",
          label: "Random",
          icon: "IconArrowsRandom",
          options: {
            count: 1,
            from: 0,
            mode: "email",
            outputVariable: "",
            password: 8,
            to: 0,
            domain: "",
          },
          search: "Random",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "fileUpload",
          typeNode: "normalNode",
          label: "File upload",
          icon: "IconUpload",
          options: {
            elementSelect: "",
            fixedElement: 1,
            attachment: "localFile",
            filePath: "",
            timeout: 30000,
          },
          search: "File upload",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "fileChoose",
          typeNode: "normalNode",
          label: "File choose event",
          icon: "IconUpload",
          options: null,
          search: "File choose event",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "readFile",
          typeNode: "normalNode",
          label: "Read file",
          icon: "IconFileInfo",
          options: null,
          search: "Read file",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "writeFile",
          typeNode: "normalNode",
          label: "Write file",
          icon: "IconFileInfinity",
          options: {
            filePath: "",
            inputVariable: "",
            writeMode: "overwrite",
            selectorType: "",
            lineByLineDelimiter: "",
            appendMod: "newline",
            lineByLineDelimiterTxt: "",
          },
          search: "Write file",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "http",
          typeNode: "normalNode",
          label: "Http",
          icon: "IconFileInfinity",
          options: {
            contentType: "application/json",
            cookies: [
              {
                id: uuidv4(),
                key: "",
                value: "",
              },
            ],
            data: "",
            dataKeyValue: [],
            dataString: "",
            headers: [
              {
                id: uuidv4(),
                key: "",
                value: "",
              },
            ],
            method: "POST",
            outputBodyVariables: [
              {
                id: uuidv4(),
                key: "",
                value: "",
              },
            ],
            outputCookiesVariables: [
              {
                id: uuidv4(),
                key: "",
                value: "",
              },
            ],
            outputHeadersVariables: [
              {
                id: uuidv4(),
                key: "",
                value: "",
              },
            ],
            url: "",
          },
          search: "Http",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "setVariable",
          typeNode: "normalNode",
          label: "Set variable",
          icon: "IconBrandBackbone",
          options: {
            operator: "",
            value: "",
            variable: "",
          },
          search: "Set variable",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "authenticatorCode",
          typeNode: "normalNode",
          label: "Authenticator Code",
          icon: "IconShieldLock",
          options: null,
          search: "Authenticator Code",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "convertJson",
          typeNode: "normalNode",
          label: "Converting JSON",
          icon: "IconTransform",
          options: null,
          search: "Converting JSON",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "downloadFile",
          typeNode: "normalNode",
          label: "Download file",
          icon: "IconFileDownload",
          options: null,
          search: "Download file",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "extractionInText",
          typeNode: "normalNode",
          label: "Extraction In Text",
          icon: "IconCodeDots",
          options: null,
          search: "Extraction In Text",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "setCookies",
          typeNode: "normalNode",
          label: "Set Cookies",
          icon: "IconCookie",
          options: null,
          search: "Set Cookies",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "getCookies",
          typeNode: "normalNode",
          label: "Get Cookies",
          icon: "IconCookie",
          options: null,
          search: "Set Cookies",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "clearCookies",
          typeNode: "normalNode",
          label: "Clear Cookies",
          icon: "IconCookieOff",
          options: null,
          search: "Clear Cookies",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "imageSearch",
          typeNode: "normalNode",
          label: "Image Search",
          icon: "IconSearch",
          options: null,
          search: "Image Search",
        },
      },
      {
        data: {
          version: 5,
          type: "imageSearchV2",
          typeNode: "normalNode",
          label: "Image Search V2",
          icon: "IconSearch",
          options: null,
          search: "Image Search",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "setData",
          typeNode: "normalNode",
          label: "Set Data",
          icon: "IconFileAnalytics",
          options: {
            dataArr: [
              {
                id: uuidv4(),
                key: "",
                value: "",
              },
            ],
          },
          search: "Set Data",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "getData",
          typeNode: "normalNode",
          label: "Get Data",
          icon: "IconClipboardData",
          options: {
            dataArr: [
              {
                id: uuidv4(),
                key: "",
                value: "",
              },
            ],
          },
          search: "Get Data",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "readGoogleSheet",
          typeNode: "normalNode",
          label: "Spreadsheet",
          icon: "IconFileSpreadsheet",
          options: null,
          search: "Spreadsheet",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "spreadsheet",
          typeNode: "normalNode",
          label: "Spreadsheet V2",
          icon: "IconFileSpreadsheet",
          options: null,
          search: "Spreadsheet V2",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "writeSheet",
          typeNode: "normalNode",
          label: "WriteSheet",
          icon: "IconFileSpreadsheet",
          options: null,
          search: "WriteSheet",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "setUserAgent",
          typeNode: "normalNode",
          label: "Set User Agent",
          icon: "IconUserCircle",
          options: null,
          search: "Set User Agent",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "deleteFile",
          typeNode: "normalNode",
          label: "Delete File",
          icon: "IconFileX",
          options: null,
          search: "Delete File",
        },
      },
      {
        data: {
          version: versions[2].autoVersion,
          type: "readMail",
          typeNode: "normalNode",
          label: "IMAP (Read mail)",
          icon: "IconMail",
          options: null,
          search: "Read Mail",
        }
      },
      {
        data: {
          version: 4,
          type: "readMailOauth2",
          typeNode: "normalNode",
          label: "Outlook Mailer (oAuth2)",
          icon: "IconMailFast",
          options: null,
          search: "Mailer",
        }
      },
    ],
  },
  {
    label: "Other",
    icon: IconCodeMinus,
    initiallyOpened: true,
    links: [
      {
        data: {
          version: versions[0].autoVersion,
          type: "pauseCode",
          typeNode: "normalNode",
          label: "Pause",
          icon: "IconPlayerPause",
          options: {
            typePauseState: "exactTime",
            exactTimeState: 0,
            fromTimeState: 0,
            description: "0 ms",
            toTimeState: 0,
          },
          search: "Pause",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "ifCode",
          typeNode: "normalNode",
          label: "If",
          icon: "IconQuestionCircle",
          options: {
            leftVariable: "",
            operator: "",
            rightVariable: "",
          },
          search: "If",
        },
      },
      {
        data: {
          version: versions[3].autoVersion,
          type: "ifCodeV2",
          typeNode: "nodeIfCodeV2",
          label: "If v2",
          icon: "IconTerminal2",
          options: {
            conditions: [{
              id: uuidv4(),
              leftVariable: "",
              operator: "",
              rightVariable: "",
            },]
          },
          search: "If v2",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "evalCode",
          typeNode: "normalNode",
          label: "Eval",
          icon: "IconCode",
          options: {
            scriptCode: "",
            outputVariable: "",
            injectVariable: [],
          },
          search: "Eval",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "whileCode",
          typeNode: "nodeLoop",
          label: "While",
          icon: "IconInfinity",
          options: {
            leftVariable: "",
            operator: "",
            rightVariable: "",
          },
          search: "Loop",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "forCode",
          typeNode: "nodeLoop",
          label: "For",
          icon: "IconRepeat",
          options: {
            selectorTypeLoop: "data",
            elementSelect: "",
            extractionType: "text",
            extractionTypeFill: "",
            loopItemSave: "",
            loopIndexSave: "",
            fromValue: "",
            toValue: "",
            times: 10,
          },
          search: "Loop",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "addComment",
          typeNode: "nodeComment",
          label: "Add comment",
          icon: "IconMessage2",
          color: "gray",
          options: {},
          search: "Add comment",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "updateProfile",
          typeNode: "normalNode",
          label: "Update profile",
          icon: "IconEdit",
          options: {
            name: { isUpdate: true, value: "" },
            status: { isUpdate: true, value: "" },
            note: { isUpdate: true, value: "" },
            tag: { isUpdate: true, value: "" },
            browser: { isUpdate: true, value: "" },
          },
          search: "Update profile",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "stopLoop",
          typeNode: "nodeStop",
          label: "Stop Loop",
          icon: "IconRepeatOff",
          color: "red",
          fontSize: 10,
          options: {},
          search: "Loop",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "stopCode",
          typeNode: "nodeStop",
          label: "Stop",
          icon: "IconBan",
          color: "red",
          options: {},
          search: "Stop",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "runOtherScript",
          typeNode: "normalNode",
          label: "Run Other Script",
          icon: "IconGitBranch",
          options: null,
          search: "normalNode",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "addLog",
          typeNode: "normalNode",
          label: "Add Log",
          icon: "IconArticle",
          options: null,
          search: "Add Log",
        },
      },
      {
        data: {
          version: versions[0].autoVersion,
          type: "disconnection",
          typeNode: "normalNode",
          label: "Disconnection",
          icon: "IconUnlink",
          options: { reconnectTime: "2000" },
        },
      },
      {
        data: {
          version: versions[3].autoVersion,
          type: "triggerExtension",
          typeNode: "normalNode",
          label: "Open Extension",
          icon: "IconPuzzle",
          options: null,
        }
      },
      {
        data: {
          version: versions[3].autoVersion,
          type: "runCommand",
          typeNode: "normalNode",
          label: "Run Command",
          icon: "IconTerminal2",
          options: null,
          search: "Run Command",
        },
      },
      {
        data: {
          version: 4,
          type: "handleDialog",
          typeNode: "normalNode",
          label: "Handle Dialog",
          icon: "IconBoxMultiple",
          options: null,
          search: "Handle Dialog",
        },
      },
    ],
  },
];
