import ActiveTab from "./Navigation/ActiveTab";
import NewTab from "./Navigation/NewTab";
import CloseTab from "./Navigation/CloseTab";
import OpenUrl from "./Navigation/OpenUrl";
import ScreenShot from "./Navigation/ScreenShot";
import Click from "./Mouse//Click";
import MouseMovement from "./Mouse/MouseMovement";
import Scroll from "./Mouse/Scroll";
import PressKey from "./Keyboard/PressKey";
import TypeText from "./Keyboard/TypeText";
import ElementExists from "./Data/ElementExists";
import GetUrl from "./Data/GetUrl";
import GetText from "./Data/GetText";
import PauseCode from "./Other/PauseCode";
import IfCode from "./Other/IfCode";
import EvalCode from "./Other/EvalCode";
import ForCode from "./Other/ForCode";
import AddComment from "./Other/AddComment";
import GetAttribute from "./Data/GetAttribute";
import Variables from "./Variable/Variables";
import Random from "./Data/Random";
import FileUpload from "./Data/FileUpload";
import ReadFile from "./Data/ReadFile";
import WriteFile from "./Data/WriteFile";
import SetVariable from "./Data/SetVariable";
import SetHttpRequest from "./Data/SetHttpRequest";
import GetCode2FA from "./Data/GetKey2Fa";
import SendTextToSelector from "./Keyboard/SendTextToSelector";
import UpdateProfile from "./Other/UpdateProfile";
import ReloadPage from "./Navigation/ReloadPage";
import DropdownSelector from "./Mouse/DropDownSelector";
import AuthenticatorCode from "./Data/AuthenticatorCode";
import ConvertJson from "./Data/ConvertJson";
import DownloadFile from "./Data/DownloadFile";
import ExtractionInText from "./Data/ExtractionInText";
import GoBack from "./Navigation/GoBack";
import SetCookies from "./Data/SetCookies";
import ClearCookies from "./Data/ClearCookies";
import AddLog from "./Other/AddLog";
import StartConfig from "./Navigation/StartConfig";
import RunOtherScript from "./Other/RunOtherScript";
import Disconnection from "./Other/Disconnection";
import SwitchFrame from "./Navigation/SwitchFrame";
import SwitchExtensionPopup from "./Navigation/SwitchExtensionPopup";
import ImageSearch from "./Data/ImageSearch";
import SetData from "./Data/SetData";
import GetData from "./Data/GetData";
import MouseWheel from "./Mouse/MouseWheel";
import FileChoose from "./Data/FileChoose";
import ReadGoogleSheet from "./Data/ReadGoogleSheet";
import Spreadsheet from "./Data/SpreadSheet";
import GetCookies from "./Data/getCookies";
import WriteSheet from "./Data/WriteSheet";
import SetUserAgent from "./Data/SetUserAgent";
import DeleteFile from "./Data/DeleteFile";
import ReadMail from "./Data/ReadMail";
import TriggerExtension from "./Other/TriggerExtension";
import RunCommand from "./Other/RunCommand";
import IfCodeV2 from "./Other/IfCodeV2";
import ElementExistsV2 from "./Data/ElementExistsV2";
import HandleDialog from "./Other/HandleDialog";
import CustomNode from "./CustomNode";
import ImageSearchV2 from "./Data/ImageSearchV2";
import ReadMailOauth2 from "./Data/ReadMailOauth2";

export const GetBodyModalComponent = {
    //Navigator
    'nodeStart': StartConfig,
    'newTab': NewTab,
    'activeTab': ActiveTab,
    'closeTab': CloseTab,
    'openUrl': OpenUrl,
    'reloadPage': ReloadPage,
    'screenShot': ScreenShot,
    'goBack':GoBack,
    'switchFrame': SwitchFrame,
    'switchExtensionPopup': SwitchExtensionPopup,
    
    //Mouse
    'click': Click,
    'mouseMovement': MouseMovement,
    'scroll': Scroll,
    'drop-down-selector': DropdownSelector,
    'mouseWheel' : MouseWheel,

    //Keyboard
    'pressKey' : PressKey,
    'typeText' : TypeText,
    'sendTextToSelector': SendTextToSelector,

    //Data
    'elementExists' : ElementExists,
    'elementExistsV2' : ElementExistsV2,
    'getUrl' : GetUrl,
    'getText' : GetText,
    'getValue' : GetText,
    'getAttributeValue': GetAttribute,
    'readFile': ReadFile,
    'fileUpload' : FileUpload,
    'fileChoose' : FileChoose,
    'writeFile' : WriteFile,
    'http': SetHttpRequest,
    'setVariable' : SetVariable,
    'getCode2FA' : GetCode2FA,
    'authenticatorCode' : AuthenticatorCode,
    'downloadFile' : DownloadFile,
    'extractionInText' : ExtractionInText,
    'setCookies' : SetCookies,
    'getCookies' : GetCookies,
    'clearCookies' : ClearCookies,
    'imageSearch' : ImageSearch,
    'imageSearchV2' : ImageSearchV2,
    'setData' : SetData,
    'getData' : GetData,
    'readGoogleSheet' : ReadGoogleSheet,
    'spreadsheet' : Spreadsheet,
    'writeSheet' : WriteSheet,
    'setUserAgent' : SetUserAgent,
    'deleteFile' : DeleteFile,
    'readMail' : ReadMail,
    'readMailOauth2' : ReadMailOauth2,

    //Variables
    'nodeVariables' : Variables,
    'random' : Random,

    //Other
    'pauseCode': PauseCode,
    'ifCode': IfCode,
    'evalCode': EvalCode,
    'whileCode': IfCode,
    'forCode': ForCode,
    'addComment': AddComment,
    'updateProfile': UpdateProfile,
    'convertJson': ConvertJson,
    'runOtherScript': RunOtherScript,
    'addLog': AddLog,
    'disconnection': Disconnection,
    'triggerExtension': TriggerExtension,
    'runCommand' : RunCommand,
    'ifCodeV2' : IfCodeV2,
    'handleDialog' : HandleDialog,

    'customNode': CustomNode,
}
